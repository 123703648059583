import { React } from 'Imports';
import { MenuItem } from 'MaterialUIComponents';
import { ClickAwayListener, InputAdornment, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';

const styles = require('$Pages/VideoEvents/components/DropdownSearchField.scss') as {
    searchBar: string;
};

interface IDropdownSearchFieldProps {
    onChange: (value: string) => void;
    searchFilter: string;
    onSearchFieldFocus: () => void;
    onSearchFieldFocusLost: () => void;
}

const DropdownSearchField = (props: IDropdownSearchFieldProps) => {
    const [searchFieldHasFocus, setSearchFieldHasFocus] = useState(false);

    const showClearButton = props.searchFilter.length > 0;
    const showSearchIcon = !showClearButton;
    const showNoAdornments = searchFieldHasFocus && props.searchFilter.length === 0;

    const baseInputProps = {
        sx: {
            textAlign: 'left',
            height: '25px',
            paddingLeft: '12px',
        },
    };

    const notFilteringInputProps = {
        ...baseInputProps,

        startAdornment: (
            <InputAdornment position="start">
                <FontAwesomeIcon icon={faSearch} color="#B5BEC9" />
            </InputAdornment>
        ),
    };

    const filteringInputProps = {
        ...baseInputProps,
        sx: {
            ...baseInputProps.sx,
            cursor: 'pointer',
        },
        endAdornment: (
            <InputAdornment position="end" onClick={() => props.onChange('')}>
                <FontAwesomeIcon icon={faXmark} color="#373E40" />
            </InputAdornment>
        ),
    };

    return (
        <MenuItem
            onKeyDown={(e) => e.stopPropagation()}
            disableRipple={true}
            sx={{
                '&.Mui-focusVisible': {
                    backgroundColor: 'transparent',
                },
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            }}
        >
            <ClickAwayListener onClickAway={() => setSearchFieldHasFocus(false)}>
                <TextField
                    variant="outlined"
                    fullWidth
                    placeholder={searchFieldHasFocus ? '' : 'Search...'}
                    value={props.searchFilter}
                    onChange={(e) => props.onChange(e.target.value)}
                    onFocus={() => {
                        setSearchFieldHasFocus(true);
                        props.onSearchFieldFocus();
                    }}
                    onBlur={() => {
                        setSearchFieldHasFocus(false);
                        props.onSearchFieldFocusLost();
                    }}
                    focused={searchFieldHasFocus}
                    className={styles.searchBar}
                    InputProps={showNoAdornments ? baseInputProps : showSearchIcon ? notFilteringInputProps : filteringInputProps}
                    sx={{
                        fontWeight: 400,
                        textAlign: 'left',
                        height: '25px',
                        paddingLeft: '8px',
                        fieldset: {
                            border: searchFieldHasFocus ? '1px solid #0D7AC5 !important' : '1px solid #C4CBD4 !important',
                        },
                    }}
                />
            </ClickAwayListener>
        </MenuItem>
    );
};

export default DropdownSearchField;
