import { React } from 'Imports';
import { useState } from 'react';
import StyledSwitch from '$Components/Shared/MaterialUIComponents/StyledSwitch';
import { Box } from '@mui/material';

const styles = require('$Pages/VideoEvents/components/DropdownSwitch.scss') as {
    toggle: string;
    toggleText: string;
};

interface IDropdownSwitchProps {
    toggleText: string;
    onChange: () => void;
}

const DropdownSwitch = (props: IDropdownSwitchProps) => {
    const [toggled, setToggled] = useState(false);

    return (
        <Box className={styles.toggle}>
            <StyledSwitch
                checked={toggled}
                onChange={() => {
                    setToggled(!toggled);
                    props.onChange();
                }}
            />
            <Box className={styles.toggleText}>{props.toggleText}</Box>
        </Box>
    );
};

export default DropdownSwitch;
