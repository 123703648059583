import { IAjaxState, React, bind } from 'Imports';
import { IIntegrationPartnerDataInjectedProps, IntegrationPartnerDataService } from '$State/IntegrationPartnerDataFreezerService';
import { VideoEventService, IVideoEventServiceInjectedProps } from '$State/VideoEventFreezerService';
import { IConfigServiceInjectedProps, ConfigService } from '$State/ConfigFreezerService';
import { IDropdownItem } from '$Components/FilterComponents/VPDropdown';
import { getVideoEventStatusDropdownList } from '$Utilities/videoEventStatusUtility';
import VideoEventFilterBarMultiSelect, { FilterObjectType } from '$Pages/VideoEvents/components/VideoEventFilterBarMultiSelect';
import { ClickAwayListener, IconButton, MenuProps } from '@mui/material';
import { VideoEventFilterMultiselectRequest, VideoEventTypeResponse } from '$Generated/api';
import { IMultiselectFilterOptions } from '$Components/FilterComponents/MultiSelectFilterBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import * as _ from 'lodash';

const styles = require('$Pages/VideoEvents/components/VideoEventFilterBar.scss') as {
    main: string;
    mainMobile: string;
    filter: string;
    switchLabel: string;
    hidden: string;
    mobileFilterTitleBar: string;
};

interface IVideoEventFilterBarProps {
    eventFilter: VideoEventFilterMultiselectRequest;
    onFilterOptionsChange: (filterOptions: IMultiselectFilterOptions) => Promise<void>;
    isMobileView: boolean;
    toggleFilterView?: () => void;
}

type VideoEventFilterBarProps = IVideoEventFilterBarProps &
    IIntegrationPartnerDataInjectedProps &
    IVideoEventServiceInjectedProps &
    IConfigServiceInjectedProps;

const getFormattedDeviceResults = (deviceResults: IAjaxState<any[]>) =>
    !deviceResults?.data || deviceResults.data.length == 0
        ? []
        : deviceResults.data.map((dr: any) => {
              return {
                  label: dr.name || 'Unknown',
                  value: dr.id || 0,
                  isActive: dr.isActive || true,
              } as IDropdownItem;
          });

const getFormattedEventTypes = (videoEventTypes: VideoEventTypeResponse[] | null | undefined) =>
    (videoEventTypes || []).map((et) => {
        return {
            label: et.displayName || 'Unknown',
            value: et.id || 0,
            isActive: true,
        } as IDropdownItem;
    });

const getFormattedDriverResults = (driverResults: IAjaxState<any[]>) => {
    return !driverResults?.data || driverResults.data.length == 0
        ? []
        : driverResults.data.map((dr: any) => {
              return {
                  label: dr.firstName + ' ' + dr.lastName || 'Unknown',
                  value: dr.id || 0,
                  isActive: dr.isActive || true,
              } as IDropdownItem;
          });
};

class _VideoEventFilterBar extends React.Component<VideoEventFilterBarProps> {
    constructor(props: any) {
        super(props);
    }

    @bind
    handleFilterChange(selectedItems: IDropdownItem[], filterObjectType: FilterObjectType) {
        const existingFilterItems = _.sortBy(this.props.eventFilter[filterObjectType] || []);
        const updatedFilterItems = _.sortBy(selectedItems.map((i) => i.value));

        // only update if the selected items have changed
        if (!_.isEqual(existingFilterItems, updatedFilterItems)) {
            this.props.onFilterOptionsChange({ ...this.props.eventFilter, [filterObjectType]: updatedFilterItems });
        }
    }

    render(): JSX.Element {
        const { videoEventTypeResults } = this.props.videoEvents.getState();
        const { deviceResults, driversResults } = this.props.integrationPartnerData.getState();

        const formattedEventTypes = getFormattedEventTypes(videoEventTypeResults.data);
        const formattedDriverResults = getFormattedDriverResults(driversResults);
        const formattedDeviceResults = getFormattedDeviceResults(deviceResults);
        const formattedEventStatuses = getVideoEventStatusDropdownList();

        const disabled =
            formattedEventStatuses.length === 0 ||
            formattedEventTypes.length === 0 ||
            formattedDeviceResults.length === 0 ||
            formattedDriverResults.length === 0;

        const scrollingMaxHeightPopoverProps: Partial<MenuProps> = {
            PaperProps: {
                style: {
                    maxHeight: 315,
                    overflowY: 'auto',
                    maxWidth: 240,
                },
            },
        };

        const onClickAwayFromDrawer = () => {
            if (this.props.isMobileView) {
                this.props.toggleFilterView && this.props.toggleFilterView();
            }
        };

        return (
            <ClickAwayListener onClickAway={onClickAwayFromDrawer}>
                <div className={this.props.isMobileView ? styles.mainMobile : styles.main}>
                    <div className={styles.filter}>
                        {this.props.isMobileView && (
                            <div className={styles.mobileFilterTitleBar}>
                                <div>Filter Videos</div>
                                <IconButton sx={{ ':hover': { background: 'transparent' } }}>
                                    <FontAwesomeIcon icon={faXmark} color="#373E40" size={'xs'} onClick={this.props.toggleFilterView} />
                                </IconButton>
                            </div>
                        )}
                        <VideoEventFilterBarMultiSelect
                            label={'Event Type'}
                            items={formattedEventTypes}
                            disabled={disabled}
                            applyFilter={this.handleFilterChange}
                            sx={{ width: this.props.isMobileView ? '100%' : '134px' }}
                            searchable={true}
                            popoverMenuProps={scrollingMaxHeightPopoverProps}
                            filterKey={'eventTypes'}
                            filter={this.props.eventFilter}
                        />

                        <VideoEventFilterBarMultiSelect
                            label={'Driver'}
                            items={formattedDriverResults}
                            disabled={disabled}
                            applyFilter={this.handleFilterChange}
                            sx={{ width: this.props.isMobileView ? '100%' : '101px' }}
                            searchable={true}
                            toggleable={true}
                            toggleText={'Include Inactive'}
                            popoverMenuProps={scrollingMaxHeightPopoverProps}
                            filterKey={'driverNames'}
                            filter={this.props.eventFilter}
                        />
                        <VideoEventFilterBarMultiSelect
                            label={'Vehicle'}
                            items={formattedDeviceResults}
                            disabled={disabled}
                            applyFilter={this.handleFilterChange}
                            sx={{ width: this.props.isMobileView ? '100%' : '109px' }}
                            searchable={true}
                            popoverMenuProps={scrollingMaxHeightPopoverProps}
                            filterKey={'vehicleIds'}
                            filter={this.props.eventFilter}
                        />
                        <VideoEventFilterBarMultiSelect
                            label={'Event Status'}
                            items={formattedEventStatuses}
                            disabled={disabled}
                            applyFilter={this.handleFilterChange}
                            sx={{ width: this.props.isMobileView ? '100%' : '148px' }}
                            filterKey={'status'}
                            filter={this.props.eventFilter}
                        />
                    </div>
                </div>
            </ClickAwayListener>
        );
    }
}

export const VideoEventFilterBar = VideoEventService.inject(
    ConfigService.inject(IntegrationPartnerDataService.inject(_VideoEventFilterBar)),
);
