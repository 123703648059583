import { React } from 'Imports';
import { Checkbox, ListItemText, MenuItem } from 'MaterialUIComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';

interface IDropdownMenuItemProps {
    key: string | number;
    value: string;
    checked: boolean;
    onChange: () => void;
    label: string;
}

const DropdownMenuItem = (props: IDropdownMenuItemProps) => (
    <MenuItem key={props.key} value={props.value} disableRipple={true} onChange={props.onChange}>
        <Checkbox
            checked={props.checked}
            sx={{
                padding: '0px 8px 0px 0px',
                color: '#D3D8DF',
            }}
            icon={<FontAwesomeIcon icon={faSquare} size="lg" />}
            checkedIcon={<FontAwesomeIcon icon={faCheckSquare} size="lg" color={'#0D7AC5'} />}
            onChange={props.onChange}
        />
        <ListItemText
            primary={props.label}
            primaryTypographyProps={{
                noWrap: true,
                fontSize: '14px',
                textOverflow: 'ellipsis',
                fontWeight: props.checked ? 700 : 400,
            }}
            sx={{
                textOverflow: 'ellipsis',
                fontWeight: props.checked ? 700 : 400,
                '&.Mui-checked': {
                    color: '#373E40',
                    fontWeight: 700,
                },
            }}
            onClick={props.onChange}
        />
    </MenuItem>
);

export default DropdownMenuItem;
